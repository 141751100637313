<footer class="footer">
  <div class="footer__wrapper d-flex w-100">
    <div class="footer-start-now d-flex w-100">
      <div>
        <div class="heading-5">دائمًا لك العزوة و السند</div>
        <p class="text-medium">
          اتصل بنا للحصول على الخدمة ، لمساعدتك في تحقيق ما تحلم به.
        </p>
      </div>
      <a class="d-flex primary-btn" href="https://consumer.ezoah.com" target="_blank" title="جمعية عزوة">
        ابدأ الان
        <span class="icon d-flex">
          <img src="../../../assets/images/arrow-up-left_mini.svg" alt="جمعية عزوة" />
        </span>
      </a>
    </div>
    <div class="footer-info w-100 d-flex">
      <div class="ozwah-info__wrapper">
        <div class="ozwah-info">
          <a routerLink="/" class="logo d-flex">
            <img loading="lazy" src="../../../assets/images/Logo.svg" alt="عزوة" />
          </a>
          <p class="text-medium">
            جمعية أهلية مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم
            (2232) وتاريخ 01/07/1443 هـ
          </p>
        </div>
        <ul class="ozwah-contact">
          <li class="contact">
            <figure class="icon">
              <img src="../../../assets/images/social-icon/phone.svg" alt="رقم الجوال" />
            </figure>
            <div class="contact-info">
              <div class="text-tiny">رقم الجوال</div>
              <div class="text-bold">0504461988</div>
            </div>
          </li>
          <li class="contact">
            <figure class="icon">
              <img src="../../../assets/images/social-icon/mail.svg" alt="البريد الالكترونى" />
            </figure>
            <div class="contact-info">
              <div class="text-tiny">البريد الالكترونى</div>
              <div class="text-bold">contact&#64;ezoah.com</div>
            </div>
          </li>
          <li class="contact">
            <figure class="icon">
              <img src="../../../assets/images/social-icon/address.svg" alt="العنوان" />
            </figure>
            <div class="contact-info">
              <div class="text-tiny">العنوان</div>
              <div class="text-bold">Riyadh, Saudi Arabia</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="ozwah-links__wrapper">
        <div class="ozwah-links">
          <!-- about us -->
          <div class="links">
            <div class="text-bold">معلومات عنا</div>
            <ul>
              <li (click)="scrollToSection('aboutSection')">
                <span class="text-small" title="عن عزوة"> عن عزوة </span>
              </li>
              <li (click)="scrollToSection('librarySection')">
                <span class="text-small" title="الأخبار"> الأخبار </span>
              </li>
              <li>
                <a class="text-small" href="https://nvg.gov.sa/auth/login" target="_blank" title="تطوع">
                  تطوع
                </a>
              </li>
              <li>
                <a class="text-small" href="../../../assets/files/الملف التعريفي لجمعية عزوة - 2024.pdf" target="_blank"
                  title="الملف التعريفي">
                  الملف التعريفي
                </a>
              </li>
              <li>
                <a class="text-small" routerLink="registration-certificate" title="شهادة التسجيل">
                  شهادة التسجيل
                </a>
              </li>
            </ul>
          </div>
          <!-- support -->
          <div class="links">
            <div class="text-bold">الدعم</div>
            <ul>
              <li (click)="contactUsForJob(false)">
                <span class="text-small" title="تواصل معنا"> تواصل معنا </span>
              </li>
              <li (click)="contactUsForJob(true)">
                <span class="text-small" title="توظيف"> توظيف </span>
              </li>
              <li>
                <a class="text-small" href="https://consumer.ezoah.com/info/questions" target="_blank"
                  title="الأسئلة الشائعة">
                  الأسئلة الشائعة
                </a>
              </li>
              <li>
                <a class="text-small" routerLink="privacy-policy" title="سياسة الخصوصية">
                  سياسة الخصوصية
                </a>
              </li>
              <li>
                <a class="text-small" href="../../../assets/files/دليل المستخدم لجمعية عزوة.pdf" target="_blank"
                  title="دليل المستخدم">
                  دليل المستخدم
                </a>
              </li>
            </ul>
          </div>
          <!-- important links -->
          <div class="links">
            <div class="text-bold">روابط قد تهمك</div>
            <ul>
              <li>
                <a class="text-small" href="https://new.najiz.sa/applications/landing" target="_blank"
                  title="بوابة ناجز">
                  بوابة ناجز
                </a>
              </li>
              <li>
                <a class="text-small" href="https://nvg.gov.sa/public/vol-opport" target="_blank"
                  title="المنصة الوطنية للعمل التطوعى">
                  المنصة الوطنية للعمل التطوعى
                </a>
              </li>
              <li>
                <a class="text-small" href="https://ncnp.gov.sa/ar" target="_blank"
                  title="المركز الوطنى لتنمية القطاع الغير ربحي">
                  المركز الوطنى لتنمية القطاع الغير ربحي
                </a>
              </li>
              <li>
                <a class="text-small" href="https://www.hrsd.gov.sa/" target="_blank"
                  title="وزارة الموارد البشرية و التمنية الإجتماعية">
                  وزارة الموارد البشرية و التمنية الإجتماعية
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="ozwah-social">
          <ul class="social-icons">
            <li class="icon">
              <a href="https://api.whatsapp.com/send/?phone=966504461988&text&type=phone_number&app_absent=0"
                target="_blank" title="what'sApp">
                <img src="../../../assets/images/social-icon/what'sapp.svg" alt="whatsapp" />
              </a>
            </li>
            <li class="icon">
              <a href="https://www.tiktok.com/@ezoahksa?_t=8mj14qNS8Ht&_r=1" target="_blank" title="tiktok">
                <img src="../../../assets/images/social-icon/tiktok.svg" alt="tiktok" />
              </a>
            </li>
            <li class="icon">
              <a href="https://www.instagram.com/ezoahksa/?igshid=NzZhOTFlYzFmZQ%3D%3D" target="_blank"
                title="instagram">
                <img src="../../../assets/images/social-icon/instagram.svg" alt="instagram" />
              </a>
            </li>
            <li class="icon">
              <a href="https://twitter.com/EzoahKSA" target="_blank" title="منصة أكس">
                <img src="../../../assets/images/social-icon/x.svg" alt="منصة أكس" />
              </a>
            </li>
            <li class="icon">
              <a href="https://www.linkedin.com/company/جمعية-عزوة-للحماية-الأسرية/" target="_blank" title="linkedin">
                <img src="../../../assets/images/social-icon/linkedin.svg" alt="linkedin" />
              </a>
            </li>
            <li class="icon">
              <a href="https://www.snapchat.com/add/ezoah_ksa" target="_blank" title="snapchat">
                <img src="../../../assets/images/social-icon/snapchat.svg" alt="snapchat" />
              </a>
            </li>
          </ul>
          <div class="copy-right text-small">2024، جميع الحقوق محفوظة ©</div>
        </div>
      </div>
    </div>
  </div>
</footer>